body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
  background-color: #342489;
  color: #ffffff;
}

ol,
ul {
  padding-left: 30px;
}

button {
  background-color: #ffffff;
  border: none;
  width: 100px;
  height: 22px;
  font-size: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.container {
  width: 400px;
  margin: 30vh auto;
}
.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.squares-container {
  display: block;
  background-color: #6748c5;
  border-radius: 5px;
  padding: 2px;
}

.square {
  background: #342267;
  border: 1px solid #342267;
  float: left;
  font-size: 32px;
  font-weight: bold;
  line-height: 68px;
  height: 68px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 68px;
  border-radius: 5px;
  margin: 3px;
  color: #ffffff;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

.win-square {
  color: #6748c5;
}
